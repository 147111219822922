import _superagent from "superagent";
import superagentPromise from "superagent-promise";

import { errorHandler } from "./responseHandler";

const superagent = superagentPromise(_superagent, global.Promise);

export const API_ROOT = process.env.REACT_APP_SERVER_URL || "";

const responseBody = (res) => res.body;

// const encode = encodeURIComponent;

export let token = null;
const tokenPlugin = (req) => {
  if (token) {
    req.set("authorization", `Bearer ${token}`);
  }
};

const requests = {
  del: (url) =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorHandler),
  get: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorHandler),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorHandler),
  post: (url, body?) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorHandler),
  formDataPost: (url, body?) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .set("Content-Type", "multipart/form-data")
      .then(responseBody)
      .catch(errorHandler),
};

const Auth = {
  login: (email, password) =>
    requests.post("/auth/sign-in?lang=en", { email, password }),
  signup: (username, email, password) =>
    requests.post("/auth/sign-up?lang=en", {
      user: { username, email, password },
    }),
  setPassword: (token, newPassword, confirmNewPassword) =>
    requests.post(`/password?lang=en&token=${token}`, {
      newPassword,
      confirmNewPassword,
    }),
  getUsers: () => requests.get("/user?lang=en"),
  profile: () => requests.get("/profile/me"),
  profileCoursesByGradeId: (gradeId) =>
    requests.get(`/profile/courses/${gradeId}`),
  profileCourses: () => requests.get("/profile/courses"),
  profileGrades: () => requests.get("/profile/grades"),
};

const Dashboard = {
  getTeacherDashboardStats: (teacherId, gradeId) =>
    requests.get(`/teacherDashboard/${teacherId}/${gradeId}`),
  getUpcomingEvents: () => requests.get("/assessment/upcomingEvents?lang=en"),
};

const Course = {
  getCategoriesInCourse: (courseId: string) =>
    requests.get(`/course/${courseId}/categories?lang=en`),
  getStrengthAndWeaknessByCourseId: (courseId: string) =>
    requests.get(`/sawT/${courseId}`),
  getTop5Bottom5: (courseId) =>
    requests.get(`/course/topbottomstudent/${courseId}`),
  getLecturesByCourseId: (courseId) => requests.get(`/lecture/${courseId}`),
  uploadLectureFile: "/upload/lecture",
  createLecture: (lectureBody) => requests.post("/lecture", { ...lectureBody }),
  updateLecture: (lectureId, lectureBody) =>
    requests.put(`/lecture/${lectureId}`, { ...lectureBody }),
  addLectureInCourse: (lectureId, courseId) =>
    requests.post("/lecture/add-course", { lectureId, courseId }),
  addLectureCategory: (lectureId, tagId) =>
    requests.post("/lecture/add-category", { lectureId, tagId }),
  lectureReorder: (courseId, lectureId, order) =>
    requests.put(`/lecture/reorder/${courseId}/${lectureId}`, { order }),
  deleteLecture: (lectureId) =>
    requests.del(`/lecture/force-delete/${lectureId}`),
};

const Category = {
  getCategories: () => requests.get("/category?lang=en"),
  getCategoriesById: (id: string) => requests.get(`/category/${id}?lang=en`),
  getCategoriesByCourseId: (courseId) =>
    requests.get(
      `/category?filtered=${JSON.stringify([
        { id: "courseId", value: courseId },
      ])}`
    ),
  createCategory: (courseId, parentId, name) =>
    requests.post("/category?lang=en", { courseId, parentId, name }),
  updateCategory: (categoryId, courseId, parentId, name) =>
    requests.put(`/category/${categoryId}?lang=en`, {
      courseId,
      parentId,
      name,
    }),
  addCommentToCategory: (categoryId, comment) =>
    requests.put(`/category/${categoryId}/add-comment?lang=en`, {
      comment,
    }),
};

const Questions = {
  getQuestions: () => requests.get("/category?lang=en"),
  getQuestionByQuestionId: (questionId) =>
    requests.get(`/question/${questionId}?lang=en`),
  getQuestionsByCourseId: (courseId) =>
    requests.get(
      `/question?lang=en&filtered=${JSON.stringify([
        { id: "courseId", value: courseId },
      ])}&pageSize=-1`
    ),
  getQuestionAuthors: (courseCode) =>
    requests.get(`/course/authors/${courseCode}`),
  autoSelectQuestions: (autoSelectBody) =>
    requests.post("/question/autoSelect?lang=en", { ...autoSelectBody }),
  filterQuestion: (filterBody) =>
    requests.post("/question/filter?lang=en", { ...filterBody }),
  createQuestion: (questionBody) =>
    requests.post("/question?lang=en", { ...questionBody }),
  updateQuestion: (questionId, questionBody) =>
    requests.put(`/question/${questionId}?lang=en`, { ...questionBody }),
  deleteQuestion: (questionId) =>
    requests.del(`/question/soft-delete/${questionId}?lang=en`),
  // GPT Questions
  bulkGenerateQuestions: (bulkGenerateBody, lang = "en") =>
    requests.post(`/question/bulkgenerate?lang=${lang}`, {
      ...bulkGenerateBody,
    }),
  bulkRgenerateQuestions: (bulkRegenerateBody, lang = "en") =>
    requests.post(`/question/regenerate?lang=${lang}`, {
      ...bulkRegenerateBody,
    }),
  bulkCreateQuestions: (bulkCreateBody) =>
    requests.post("/question/bulkcreate?lang=en", [...bulkCreateBody]),
};

const Assessments = {
  getAssessments: () => requests.get("/assessment?lang=en"),
  getAssessmentsByGrade: (submissionId) =>
    requests.get(`/assessment/submission/${submissionId}?lang=en`),
  getAssessmentsByCourseId: (courseId) =>
    requests.get(
      `/assessment?lang=en&filtered=${JSON.stringify([
        { id: "courseId", value: courseId },
      ])}&pageSize=100`
    ),
  getAssessmentsByCourseCodeAndTeacherId: (courseCode, teacherId) =>
    requests.get(
      `/assessment/getByCourseCode/${courseCode}/${teacherId}?pageSize=100`
    ),
  getAssessmentById: (assessmentId) =>
    requests.get(`/assessment/${assessmentId}?lang=en`),
  createAssessment: (assessmentBody) =>
    requests.post("/assessment?lang=en", { ...assessmentBody }),
  deleteAssessment: (assessmentId) =>
    requests.del(`/assessment/soft-delete/${assessmentId}?lang=en`),
  updateAssessment: (assessmentId, assessmentBody) =>
    requests.put(`/assessment/${assessmentId}?lang=en`, { ...assessmentBody }),
  updateAssessmentWeightage: (assessmentId, assessmentBody) =>
    requests.post(`/assessment/${assessmentId}/weightage?lang=en`, {
      ...assessmentBody,
    }),
  updateAssessmentDueDate: (assessmentId, assessmentBody) =>
    requests.post(`/assessment/${assessmentId}/updateDueDate?lang=en`, {
      ...assessmentBody,
    }),
  publishAssessment: (assessmentBody) =>
    requests.post("/assessment/publishAssessment?lang=en", {
      ...assessmentBody,
    }),
  submitAssessment: (assessmentBody) =>
    requests.post("/assessment/submitAssessment?lang=en", {
      ...assessmentBody,
    }),
  gradeSubmission: (gradeSubmissionBody) =>
    requests.post("/assessment/gradeSubmission?lang=en", {
      ...gradeSubmissionBody,
    }),
  getAssessmentWeightage: (courseId) =>
    requests.get(`/assessment/${courseId}/weightage?lang=en`),
  archiveAssessment: (assessmentId) =>
    requests.post(`/assessment/${assessmentId}/archived?lang=en`),
  getAssessmentSubmissions: (assessmentId) =>
    requests.get(`/assessment/${assessmentId}/submissions`),
};

const Student = {
  getStudents: () => requests.get("/students/"),
  getStudentAssessments: (studentId) =>
    requests.get(`/students/${studentId}/assignments`),
  getStudentExams: (studentId) => requests.get(`/students/${studentId}/exams`),
  getStudentById: (studentId) => requests.get(`/students/${studentId}`),
  getStudentsByGradeId: (gradeId) => requests.get(`/students/grade/${gradeId}`),
  getStudentsSAWById: (studentId) => requests.get(`/sawS/${studentId}`),
  getStudentStatsByStudentId: (profileId: string) =>
    requests.get(`/studentDashboard/${profileId}`),
  getStudentStengthAndWeakness: (studentId, gradeId) =>
    requests.get(`/sawS/${studentId}/${gradeId}`),
};

const agent = {
  Auth,
  Course,
  Student,
  Category,
  Questions,
  Dashboard,
  Assessments,
  setToken: (_token) => (token = _token),
};

export default agent;
