import i18next from "i18next";

// Questions
export const QuestionTypeResolver = {
  1: i18next.t("questionTypes.mcq"),
  2: i18next.t("questionTypes.descriptive"),
  3: i18next.t("questionTypes.pictorial"),
  4: i18next.t("questionTypes.whiteBoard"),
};

export const QuestionTypes = {
  [i18next.t("questionTypes.mcq")]: 1,
  [i18next.t("questionTypes.descriptive")]: 2,
  [i18next.t("questionTypes.pictorial")]: 3,
  [i18next.t("questionTypes.whiteBoard")]: 4,
};

export const QuestionBankResolver = {
  1: i18next.t("questionBankTypes.assessment"),
  2: i18next.t("questionBankTypes.test"),
};

export const QuestionBanks = {
  [i18next.t("questionBankTypes.assessment")]: 1,
  [i18next.t("questionBankTypes.test")]: 2,
};

export const QuestionDifficultyResolver = {
  1: i18next.t("questionDifficulties.easy"),
  2: i18next.t("questionDifficulties.medium"),
  3: i18next.t("questionDifficulties.hard"),
  4: i18next.t("questionDifficulties.hardest"),
  5: i18next.t("questionDifficulties.inferno"),
};

export const DifficultyScale = {
  10: 1, // Easy
  30: 2, // Medium
  50: 3, // Hard
  70: 4, // Hardest
  90: 5, // Inferno
};

// Assignments
export const AssessmentStatus = {
  [i18next.t("assessmentStatuses.drafted")]: 1,
  [i18next.t("assessmentStatuses.published")]: 2,
  [i18next.t("assessmentStatuses.submitted")]: 3,
  [i18next.t("assessmentStatuses.checked")]: 4,
  [i18next.t("assessmentStatuses.graded")]: 5,
  [i18next.t("assessmentStatuses.pending")]: 6,
  [i18next.t("assessmentStatuses.inProgress")]: 7,
};

export const AssessmentStatusResolver = {
  1: i18next.t("assessmentStatuses.drafted"), // Assignment creation in progress, not yet published
  2: i18next.t("assessmentStatuses.published"), // Assignment is publically available
  3: i18next.t("assessmentStatuses.submitted"), // Student filled and submitted the assignment, but teacher yet to grade
  4: i18next.t("assessmentStatuses.checked"), // Student filled and submitted the assignment, teacher also checked the assignment. This is non-gradeable assignment
  5: i18next.t("assessmentStatuses.graded"), // Student filled and submitted the assignment, teacher also graded the assignment.
  6: i18next.t("assessmentStatuses.pending"), // Student hasn't filled and/or submitted the assignment yet
  7: i18next.t("assessmentStatuses.inProgress"), // Allah jane
};

export const AssessmentTypes = {
  [i18next.t("assessmentTypes.assignment")]: 1,
  [i18next.t("assessmentTypes.exam")]: 2,
  [i18next.t("assessmentTypes.quiz")]: 3,
};

export const AssessmentTypesResolver = {
  1: i18next.t("assessmentTypes.assignment"),
  2: i18next.t("assessmentTypes.exam"),
  3: i18next.t("assessmentTypes.quiz"),
};

export const AssessmentDifficulty = {
  [i18next.t("questionDifficulties.easy")]: 1,
  [i18next.t("questionDifficulties.medium")]: 2,
  [i18next.t("questionDifficulties.hard")]: 3,
  [i18next.t("questionDifficulties.hardest")]: 4,
  [i18next.t("questionDifficulties.inferno")]: 5,
};

export const SliderDifficultyLevels = [
  {
    value: 10,
    label: i18next.t("questionDifficulties.easy"),
  },
  {
    value: 30,
    label: i18next.t("questionDifficulties.medium"),
  },
  {
    value: 50,
    label: i18next.t("questionDifficulties.hard"),
  },
  {
    value: 70,
    label: i18next.t("questionDifficulties.hardest"),
  },
  {
    value: 90,
    label: i18next.t("questionDifficulties.inferno"),
  },
];

export const LectureTypes = {
  [i18next.t("lectureTypes.video")]: 1,
  [i18next.t("lectureTypes.audio")]: 2,
  [i18next.t("lectureTypes.theory")]: 3,
};

export const LectureTypesResolver = {
  1: i18next.t("lectureTypes.video"),
  2: i18next.t("lectureTypes.audio"),
  3: i18next.t("lectureTypes.theory"),
};
