import i18next from "i18next";

export const PUBLIC_ROUTES = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  SET_PASSWORD: "/set-password",
  REGISTER: "/register",
  ALL: "*",
};

export const PROTECTED_ROUTES = {
  ALL: "/",
  DASHBOARD: "/dashboard",
  CATEGORY: "/category",
  CATEGORY_MANAGEMENT: "/category/category-management",
  COURSE: "/course",
  COURSE_DETAILS: "/course/course-details",
  COURSE_TOPIC_DETAILS: "/course/course-details/topic-details",
  COURSE_TOPIC_ADD_LECTURE: "/course/course-details/topic-details/add-lecture",
  QUESTIONS: "/questions",
  QUESTIONS_LISTING: "/questions/questions-list",
  QUESTIONS_DETAILS: "/questions/question-details",
  ADD_QUESTIONS: "/questions/add-new-question",
  ADD_BULK_QUESTIONS: "/questions/add-bulk-questions",
  BULK_QUESTIONS_LISTING: "/questions/bulk-questions-lisitng",
  ASSIGNMENT: "/assignment",
  ADD_ASSIGNMENTS: "/assignment/add-new-assignment",
  PREVIEW_ASSIGNMENT: "/assignment/preview-assignment",
  ASSIGNMENT_LISTING: "/assignment/assignment-list",
  ASSIGNMENT_SUBMITTED_LISTING: "/assignment/submitted-assignment-list",
  STUDENT: "/student",
  STUDENT_DETAILS: "/student/student-details",
  STUDENT_ASSIGNMENT_GRADING: "/student/submission-grading",
  STUDENT_PREVIEW_ASSIGNMENT: "/student/submission-preview",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS: "/terms",
};

export const SideBarRoutes = [
  {
    label: i18next.t("drawer.dashboard"),
    route: PROTECTED_ROUTES.DASHBOARD,
    icon: "icon-dashboard",
  },
  {
    label: i18next.t("drawer.category"),
    route: PROTECTED_ROUTES.CATEGORY,
    icon: "icon-category",
  },
  {
    label: i18next.t("drawer.course"),
    route: PROTECTED_ROUTES.COURSE,
    icon: "icon-course",
  },
  {
    label: i18next.t("drawer.questions"),
    route: PROTECTED_ROUTES.QUESTIONS,
    icon: "icon-question",
  },
  {
    label: i18next.t("drawer.assessment"),
    route: PROTECTED_ROUTES.ASSIGNMENT,
    icon: "icon-assignment",
  },
  {
    label: i18next.t("drawer.student"),
    route: PROTECTED_ROUTES.STUDENT,
    icon: "icon-students",
  },
];
